export const config = {
  apiUrl: process.env.REACT_APP_AT_API_URL,
  altImg: "images/no_avatar_profile.jpg",
  baseUrl: process.env.REACT_APP_AT_MAIN_URL + '/',
  altInvImg: "images/inv_img.jpeg",
  mainUrl: process.env.REACT_APP_AT_MAIN_URL,
  appUrl: process.env.REACT_APP_AT_APP_URL,
  facebookAppId: "",
  googleAppId: "",
  _api: (path) => {
    return config.apiUrl + "/api/" + path;
  },
  dsn: process.env.REACT_APP_AT_DSN,
};

